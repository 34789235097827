import React from 'react';

import Layout from '../components/Layout';

import { Link } from 'gatsby';
import Sidebar from '../components/Sidebar';
import config from '../../config';
const IndexPage = () => (
  <Layout>
    <Sidebar />
    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="about"
      >
        <div className="w-100">
          <h1 className="mb-0">
            {config.firstName}
          </h1>
          <h1 className="mb-0">
            <span className="text-primary">{config.lastName}</span>
          </h1>
          <div className="subheading mb-5">
            {config.address}  {config.phone} 
            <a href={`mailto:${config.email}`}>{config.email}</a>
          </div>
          <p className="lead mb-5">
          Artificial intelligence, photonics and optoelectronics researcher. Experience and interest in machine learning, biomedical imaging, photonic design, artificial vision, quantum mechanics, and quantum field theory. Hertz Fellow, Fulbright Research Fellow, Astronaut Scholar.

          </p>
          <div className="social-icons">
            {config.socialLinks.map(social => {
              const { icon, url } = social;
              return (
                <a key={url} href={url}>
                  <i className={`fab ${icon}`}></i>
                </a>
              );
            })}
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="experience"
      >
        <div className="w-100">
          <h2 className="mb-5">Experience</h2>
          
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Graduate Student Researcher</h3>
              <div className="subheading mb-3">University of California: Berkeley</div>
              <p>
                
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2020 - Present</span>
            </div>
          </div>
          
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Founder and President</h3>
              <div className="subheading mb-3">Clemson Fusion Engineering Team</div>
              <p>
                
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2019 - 2020</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Head and Founder</h3>
              <div className="subheading mb-3">Clemson ThinSat Team</div>
              <p>
                
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2018 - 2020</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Head and Founder</h3>
              <div className="subheading mb-3">Clemson Small Satellite Program</div>
              <p>
                
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2018 - 2020</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">Head of Avionics</h3>
              <div className="subheading mb-3">Clemson University Rocketry Team</div>
              <p>
                
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2017 - 2020</span>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="education"
      >
        <div className="w-100">
          <h2 className="mb-5">Education</h2>
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">University of California: Berkeley</h3>
              <div className="subheading mb-3">PhD</div>
              <div>Electrical Engineering</div>
              
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">May 2025</span>
            </div>
          </div>
          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Clemson University</h3>
              <div className="subheading mb-3">Bachelor of Science</div>
              <div>Electrical Engineering</div>
              
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">May 2020</span>
            </div>
          </div>
          

          
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="skills"
      >
        <div className="w-100">
          <h2 className="mb-5">Skills</h2>

          
          

          
          <ul className="fa-ul mb-0">
            <li>
              <i className="fa-li fa fa-check"></i>
              Biomedical Imaging
            </li>
            <li>
              <i className="fa-li fa fa-check"></i>
              Photonic Design
            </li>
            <li>
              <i className="fa-li fa fa-check"></i>
              Photonic fabrication
            </li>
            <li>
              <i className="fa-li fa fa-check"></i>
              Application of machine learning to optoelectronic systems
            </li>
            <li>
              <i className="fa-li fa fa-check"></i>
              Electrical-photonic interfacing
            </li>
            <li>
              <i className="fa-li fa fa-check"></i>
              Embedded Linux system programming
            </li>
            <li>
              <i className="fa-li fa fa-check"></i>
              Photonic simulation
            </li>
            <li>
              <i className="fa-li fa fa-check"></i>
              Cavity Quantum Electrodynamics (QED) simulation
            </li>
          </ul>
        </div>
      </section>

      <hr className="m-0" />


     {}
    </div>
  </Layout>
);

export default IndexPage;
